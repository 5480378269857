.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,html{
  height: 100%;
  width: 100%;
}

.full{
  height: 100%;
  width: 100%;
}

body{
  background: #00c6ff;
}
.grid{
  display: grid;
}
.gameItems{
  justify-self: center;
  align-self: center;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
}

.gameItem {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  flex: 1 1 300px;
  margin: 10px;
  grid-template-areas: "a";
  border-radius: 10px;
  border: black 2px solid;
  background: white;
}


.gameItem > img{
  border-radius: 10px;
  grid-row: 1/1;
  grid-area: a;
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.gameItem > p{
  color:white;
  padding: 10px;
  border:white  2px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0px -2px 100px rgba(34,34,34,0.6);
  grid-column: 1/2;
  grid-area: a;
  justify-self: center;
  align-self: end;
}
